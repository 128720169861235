import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Subscription } from "@/types/subscription";
import { styled } from "@mui/material/styles";
import premiumBgImg from "@assets/images/premium-bg.png";
import { CurrentPlan } from "@pages/AccountPage/CurrentPlan";
import {
  getGetUserQueryKey,
  useDeleteStripeSubscription,
  useGetStripeCreateCheckoutSession,
  useGetStripeSubscriptionProducts,
  useUpdateToPaidStripeSubscription,
} from "@thesparklaboratory/teetimeportal-react-query-client";
import { logger } from "@services/Logger";
import { useSnackbar } from "notistack";
import { LoadingWrapper } from "@common";
import { queryClient } from "@services/QueryClient";
import { useMemo, useState } from "react";
import { differenceInCalendarDays, isBefore } from "date-fns";
import { useConfirmSubscriptionUpdate, useUser } from "@services/User";

export const BackgroundImageContainer = styled(Box)({
  width: "100%",
  height: "100%",
  backgroundImage: `url(${premiumBgImg})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "relative",
  overflow: "hidden",
  "&::before": {
    content: "''",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, 0.65)", // Adjust the opacity as needed
  },
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "4px",
});

export const SubscriptionTab = ({
  subscription,
}: {
  subscription: Subscription | null;
}) => {
  const { data: userData } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [fireFetch, setFireFetch] = useState(false);
  const { isPending: isSubscriptionUpdating } = useConfirmSubscriptionUpdate({
    currentValue: userData?.data?.subscription?.updatedAt,
    enabled: fireFetch,
    disableFct: () => setFireFetch(false),
  });

  const {
    mutate: createCheckoutSession,
    isPending: isCreateSubscriptionPending,
  } = useGetStripeCreateCheckoutSession();

  const {
    mutate: updateToPaidSubscription,
    isPending: isUpdateSubscriptionPending,
  } = useUpdateToPaidStripeSubscription();

  const isTrialExpired = useMemo(() => {
    if (!subscription?.trialEndsAt) return false;

    return isBefore(new Date(subscription?.trialEndsAt), new Date());
  }, [subscription?.trialEndsAt]);

  const cancelDate = useMemo(() => {
    if (!subscription?.cancelsAt) return null;
    return new Date(subscription.cancelsAt).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  }, [subscription?.cancelsAt]);
  const renewDate = useMemo(() => {
    if (!subscription?.renewsAt) return null;
    return new Date(subscription.renewsAt).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  }, [subscription?.renewsAt]);

  const timeLeft = useMemo(() => {
    if (!subscription) {
      return null;
    }
    const { trialEndsAt } = subscription;
    const daysUntil = differenceInCalendarDays(
      new Date(trialEndsAt),
      new Date(),
    );
    const dateEnding = new Date(trialEndsAt).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    return { days: daysUntil, date: dateEnding };
  }, [subscription]);
  const { data: productsResponse } = useGetStripeSubscriptionProducts();
  const { mutate: deleteSubscription, isPending: isDeletingSubscription } =
    useDeleteStripeSubscription();

  const handleCreateCheckoutSession = async ({
    isTrial,
  }: {
    isTrial: boolean;
  }) => {
    const email = userData?.data?.email;
    const productId = productsResponse?.data[0]?.id;
    if (!email || !productId) {
      logger.error({
        message: "Error creating checkout session",
        error: "Email or product id is missing",
        data: { email, productId },
      });
      return enqueueSnackbar("Error upgrading subscription, please try again", {
        variant: "error",
      });
    }

    createCheckoutSession(
      {
        data: {
          email,
          productId,
          isTrial,
          successUrl: isTrial ? "/success" : "/success?sub=premium",
          cancelUrl: "/cancel",
        },
      },
      {
        onSuccess: (data: { data: string }) => {
          const checkoutUrl = data?.data;
          window.location.href = checkoutUrl; // Redirect to the provided URL
        },
        onError: (error) => {
          enqueueSnackbar("Subscription was not created, please try again", {
            variant: "error",
          });
          logger.error({ message: "Error creating subscription", error });
        },
      },
    );
  };

  const handleDelete = () => {
    setFireFetch(true);
    deleteSubscription(
      {
        subscriptionId: subscription?.subscriptionId || "",
      },
      {
        onSuccess: async () => {
          enqueueSnackbar("Subscription has been cancelled", {
            variant: "success",
          });

          await queryClient.invalidateQueries({
            queryKey: getGetUserQueryKey(),
          });
        },
      },
    );
  };

  const updateSubscription = () => {
    setFireFetch(true);
    updateToPaidSubscription(
      {
        subscriptionId: subscription?.subscriptionId || "",
      },
      {
        onSuccess: async () => {
          enqueueSnackbar("Subscription has been updated", {
            variant: "success",
          });

          await queryClient.invalidateQueries({
            queryKey: getGetUserQueryKey(),
          });
        },
      },
    );
  };

  return (
    <LoadingWrapper
      visible={
        isCreateSubscriptionPending ||
        isDeletingSubscription ||
        isUpdateSubscriptionPending ||
        isSubscriptionUpdating
      }
    >
      <Container maxWidth={"sm"}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {subscription?.type === "FREE" && (
              <CurrentPlan type={"Basic"} price={"Free"} trialLeft={null} />
            )}
            {subscription && subscription?.type === "TRIAL" && (
              <CurrentPlan
                type={"Premium"}
                price={"$19.99/month"}
                trialLeft={timeLeft?.days || null}
                renewsAt={renewDate}
                cancelsAt={cancelDate}
              />
            )}
            {subscription && subscription?.type === "PREMIUM" && (
              <CurrentPlan
                type={"Premium"}
                price={"$19.99/month"}
                trialLeft={null}
                renewsAt={renewDate}
                cancelsAt={cancelDate}
              />
            )}
          </Grid>

          {subscription &&
            (subscription?.type === "TRIAL" ||
              subscription?.type === "PREMIUM") && (
              <>
                <Grid item xs={12} mt={1}>
                  {!subscription?.cancelsAt && (
                    <Button onClick={handleDelete}>
                      <Typography variant={"link"}>
                        Cancel Subscription
                      </Typography>
                    </Button>
                  )}

                  {subscription?.cancelsAt && (
                    <Button onClick={updateSubscription}>
                      <Typography variant={"link"}>
                        Reactivate Subscription
                      </Typography>
                    </Button>
                  )}
                </Grid>
                <Grid container spacing={1} mt={1}>
                  <Grid
                    item
                    xs={12}
                    sx={{ textAlign: "center", color: "text.grey" }}
                  >
                    <Typography variant={"f1"}>
                      Your current subscription period will last until{" "}
                      {subscription.type === "TRIAL"
                        ? timeLeft?.date
                        : renewDate}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ textAlign: "center", color: "text.grey" }}
                  >
                    <Typography variant={"f1"}>
                      If you cancel anytime on or before that date you will not
                      be charged after your current subscription period ends.
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}

          {subscription?.type === "FREE" && (
            <Grid item xs={12}>
              <BackgroundImageContainer>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    color: "white",
                    zIndex: 1,
                    padding: "32px",
                  }}
                >
                  <Grid item xs={12}>
                    <Typography variant={"h4"}>
                      Upgrade to Premium to see tee times 10 days in advance!
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                      <Typography variant={"h3"}>$19.99</Typography>
                      <Typography sx={{ ml: 1 }}>per user/month</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    {isTrialExpired ? (
                      <Button
                        variant={"secondary"}
                        onClick={() =>
                          handleCreateCheckoutSession({ isTrial: false })
                        }
                      >
                        Start your Premium Subscription
                      </Button>
                    ) : (
                      <Button
                        variant={"secondary"}
                        onClick={() =>
                          handleCreateCheckoutSession({ isTrial: true })
                        }
                      >
                        Start a 30-day Premium trial
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </BackgroundImageContainer>
            </Grid>
          )}
        </Grid>
      </Container>
    </LoadingWrapper>
  );
};
