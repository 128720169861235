import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { SectionContainer } from "@pages/LandingPage/styles";
import { RouterLink as Link } from "@common";
const PlanCard = styled(Box)({
  background: "#000",
  color: "#fff",
  padding: 24,
  borderRadius: 6,
  minHeight: 300,
  maxWidth: "500px",
});

function JoinSection() {
  return (
    <SectionContainer mb={10}>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Grid sx={{ textAlign: "center" }}>
              <Typography
                variant="p1"
                gutterBottom
                color="green.secondary"
                sx={{ textTransform: "uppercase", textAlign: "center" }}
              >
                Premium Membership
              </Typography>
              <Typography
                variant="h2"
                gutterBottom
                sx={{
                  margin: "0 auto",
                  my: 2,
                  width: {
                    xs: "75%",
                  },
                }}
              >
                Join TeeTime Premium today
              </Typography>
              <Typography
                variant={"p2"}
                sx={{
                  display: "flex",
                  margin: "0 auto",
                  width: {
                    xs: "100%",
                    md: "75%",
                  },
                }}
                gutterBottom
                color="text.grey"
              >
                Sign up now and tee off with confidence, knowing you have the
                best tee times at your fingertips. Elevate your golfing game
                with TeeTime Premium – where excellence meets convenience!
              </Typography>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{
                mt: {
                  xs: 1,
                  md: 8,
                },
              }}
            >
              <Grid item xs={12} md={6}>
                <PlanCard>
                  <Typography variant="h4" gutterBottom>
                    Basic Plan
                  </Typography>
                  <Typography variant="h3">
                    Free <Typography variant={"p2"}>per user/month</Typography>
                  </Typography>
                  <Box my={3}>
                    <Link to={"/create-account"}>
                      <Button variant="primary">Create a Basic account</Button>
                    </Link>
                  </Box>
                  <Divider sx={{ color: "#8B8FA3" }} />
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography variant={"p2"}>
                            Access to hundreds of golf courses
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography variant={"p2"}>
                            View golf courses up to 3 days in advance
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </PlanCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <PlanCard>
                  <Typography variant="h4" gutterBottom>
                    Premium Plan
                  </Typography>
                  <Typography variant="h3">
                    $19.99{" "}
                    <Typography variant={"p2"}>per user/month</Typography>
                  </Typography>
                  <Box my={3}>
                    <Link to={"/create-account?sub=trial"}>
                      <Button variant="secondary">
                        Start a 30-day Premium trial
                      </Button>
                    </Link>
                  </Box>
                  <Divider sx={{ color: "#8B8FA3" }} />
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography variant={"p2"}>
                            Access to hundreds of golf courses
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography variant={"p2"}>
                            View golf courses up to{" "}
                            <Typography
                              variant={"p2"}
                              sx={{ display: "inline-flex" }}
                              color="green.primary"
                            >
                              10
                            </Typography>{" "}
                            days in advance
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </PlanCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </SectionContainer>
  );
}

export default JoinSection;
